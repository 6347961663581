.toolbar {
    position: relative;
    min-height: 50px !important;
    display: flex;
    justify-content: end;
}
.menu-logo {
    width: 200px;
    position: absolute;
    left: 0;
}

.appbar-container {
    display: flex;
    align-items: center;
}
.dropdown {
    background: linear-gradient(0deg, #292929, #363636) !important;
    padding: 0 10px;
}
.dropdown .MuiList-root {
    border: none;
    color: #fff;
}
.dropdown .MuiList-root > li {
    border-bottom: 1px solid rgba(255,255,255,0.5);
}
.dropdown .MuiList-root > li:last-child {
    border-bottom: none;
}

a {
    color: #fff;
    text-decoration: none;
}

.activebtn {
    color: #fff;
    text-decoration: none;
    border-bottom: 3px solid #3760D9;
}

.fondo-activo {
    font-style: italic;
    font-size: 0.75rem;
    margin-bottom: 0;
}