.nuevoUsuario-container {
    padding-top: 65px;
    justify-content: center;
}

.nuevousuario-card {
    padding: 10px 20px;
    margin-bottom: 15px;
}

.form-container {
    margin-bottom: 20px;
}

.subtitle {
    margin: 20px 20px;
}

.info-boxes {
    background-color: rgba(255,255,255,0.1);
    border-radius: 5px;
    padding: 10px 15px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 10px;
    min-height: 55px;
    display: flex;
    align-items: center;
}