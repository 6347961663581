.home-grid-container {
    padding-top: 65px;
}
.MuiToggleButton-buttonOutlined {
    border: solid 1px #fff !important;
    color: #fff !important;
    padding: 5px 10px !important;
    text-transform: capitalize !important;
    font-weight: 400 !important;
}
.MuiToggleButton-buttonOutlined[aria-pressed="false"] {
    border: solid 1px #fff !important;
    color: #fff !important;
    opacity: 0.5;
}

.top-charts {
    height: 35vh;
    box-sizing: border-box;
    padding-top: 0 !important;
}

.home-cards {
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    margin-top: 10px;
}
.home-cards.home-cards-line {
    padding: 20px 10px;
}
.home-cards.home-cards-pie {
    padding: 0px 20px 40px 20px;
}
.tableFilters {
    display: flex;
    align-items: center;
    margin-top: 40px !important;
    height: 6vh;
}
.MuiOutlinedInput-root {
    color: #fff !important;
}
.MuiOutlinedInput-root > fieldset {
    border: 1px solid #fff;
}
.MuiOutlinedInput-root:hover > fieldset.MuiOutlinedInput-notchedOutline {
    border: 1px solid #fff;
}
.labels {
    color: #fff !important;
    padding: 0 !important;
    line-height: 1 !important;
    top: -2px !important;
}
.datePickerContainer > label {
    top: -5px !important;
    line-height: 1 !important;
}
.datePickerContainer > div > input{
    padding: 10px 14px;
}
.datePickers {
    color: #fff !important;
}
.datePickers > fieldset > legend {
    color: #fff !important;
}
.datePickerCalendars svg {
    fill: #fff !important;
}
.filterTitles {
    margin: 0 15px !important;
}
.filters-select > .MuiSelect-select {
    padding: 10px 14px;
}
.filters-select > svg {
    fill: #fff !important;
}