* {
    box-sizing: border-box;
}

.info-titles {
    margin-left: 15px;
}

.info-boxes {
    background-color: rgba(255,255,255,0.1);
    border-radius: 5px;
    padding: 10px 15px;
    width: 100%;
    margin-top: 4px;
    margin-bottom: 10px;
    min-height: 39px;
}