.fondos-container {
    padding-top: 65px;
}

.buttons-container {
    display: flex;
    justify-content: end;
}
.buttons-container button {
    margin-right: 20px;
}

.table-container {
    max-height: 80vh;
    overflow: auto;
    margin-top: 20px;
    padding: 20px;
}