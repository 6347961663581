.pagos-container {
    padding-top: 75px;
    flex-direction: column;
    display: flex;
    justify-content: center;
}

.descarga-centered {
    height: 80vh;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
}

.table-container {
    padding: 30px;
    width: 100%;
}

.table-container > .table > table {
    min-width: 10px !important;
}

.downloadIcon {
    width: 18px;
    margin-right: 10px;
}

.done-payments-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 40vw;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    background: linear-gradient(#363636, #292929);
    padding: 30px;
    border-radius: 10px;
}

.done-payments-modal .btn-container {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.done-payments-modal .btn-container button:first-child {
    width: 60%;
}
.done-payments-modal .btn-container button:last-child {
    width: 38%;
}

.notPayedTable {
    margin-top: 20px;
    width: 100%;
}
.notPayedTable table {
    min-width: 0;
}

.modalButtonsContainer {
    padding-top: 30px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.checkbox {
    color: #fff;
    fill: #fff;
}