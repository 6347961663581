table thead {
    padding-bottom: 10px;
}
.table.standard-table > table > thead > tr > th {
    margin-bottom: 20px;
}

.table.standard-table tr td {
    padding: 9px 0;
}

.with-total:last-child {
    border-top: 1px solid #fff;
}

.with-total:last-child > td {
    padding-top: 15px !important;
}

.with-total:last-child > td > span {
    color: #fff !important;
}

.with-total:nth-last-child(2) > td, .with-total:nth-last-child(2) > th {
    padding-bottom: 25px !important;
}

.sticky-header-cell {
    background-color: #353535 !important;
    border-bottom: solid 1px #fff !important;
    width: 25%;
}

.sticky-cell {
    width: 25%;
}