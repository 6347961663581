.search .MuiOutlinedInput-root {
    border-radius: 5px;
    padding: 5px 15px !important;
    background-color: rgba(255,255,255,0.1);
}

.search .MuiAutocomplete-endAdornment svg {
    fill: #fff !important;
}

.search #combo-box-label {
    color: #fff;
    top: -4px;
}