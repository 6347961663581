.login-card {
    padding: 10px 20px;
    margin-bottom: 15px;
}

.login-card .card-content {
    flex-direction: column;
    display: flex;
}

.logotipo {
    width: 220px;
    margin: auto;
    display: block;
}

.textfield {
    border: 1px solid #fff;
    background-color: rgba(255,255,255,0.1);
    border-radius: 5px;
    margin: 5px 0 0 0 !important;
    width: 100%;
}
.textfield > .MuiOutlinedInput-root > fieldset {
    border: none;
    border-color: #fff;
}
.textfield > .MuiOutlinedInput-root > input:hover + fieldset {
    border: none;
    border-color: #fff;
    border-width: 2px;
}

.form-container {
    margin-bottom: 20px;
}
.buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 30px auto 10px auto;
}