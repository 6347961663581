.pagadora-profile {
    display: flex;
    align-items: center;
}
.pagadora-profile > div {
    position: relative;
}
.pagadora-profile > div > img {
    width: 25px;
    margin-right: 10px;
    position: relative;
}
.pagadora-profile > div > div {
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50px;
    background-color: #3760D9;
    z-index: 10;
    display: flex;
    justify-content: center;
    align-items: center;
    bottom: -2px;
    right: 0px;
    cursor: pointer;
}

.collection-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 35vw;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    background: linear-gradient(#363636, #292929);
    padding: 30px;
    border-radius: 10px;
}

.modal-item {
    margin: 10px 0;
}
.modal-item > div {
    background-color: rgba(255,255,255,0.1);
    padding: 10px;
    border-radius: 10px;
    margin-top: 5px;
}
.modal-item > div span {
    color: rgba(255,255,255,0.5);
}

.modal-action {
    display: flex;
    justify-content: end;
    align-items: center;
    margin-top: 10px;
}
.modal-action p {
    margin-right: 50px;
    text-decoration: underline;
    cursor: pointer;
}

.reschedule-record-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 700px;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    background: linear-gradient(#363636, #292929);
    padding: 30px;
    border-radius: 10px;
}

.record-modal-users {
    margin-bottom: 10px !important;
    border-bottom: 1px solid #fff;
}