.transaction-container {
    padding-top: 65px;
    justify-content: center;
}

.whiteDropdown {
    background: linear-gradient(#fff, #E8E8E8);
}

.whiteDropdown > ul > li {
    border-bottom: 1px solid #363636;
    margin-right: 10px;
    margin-left: 10px;
}
.whiteDropdown > ul > li:last-child {
    border-bottom: none;
}

.balance-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80vw;
    box-shadow: 24px;
    background: linear-gradient(#363636, #292929);
    max-height: 100vh;
    padding: 30px;
    border-radius: 10px;
}

.certificados-card-container {
    padding: 30px;
}

.paying-company-cards {
    /* height: 26vh; */
    height: 60vh;
    padding: 20px 20px 20px 20px;
    margin: 0px 0px;
}

.paying-company-cards > div > .table > table {
    min-width: 10px !important;
}

.add-offer > div {
    margin: 0 5px;
}

.add-offer-btn {
    float: right; 
    padding: 5px 15px !important;
    min-width: none;
    margin-top: 5px !important;
    margin-right: 5px !important;
}

.transactions-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 40vw;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    background: linear-gradient(#363636, #292929);
    padding: 30px;
    border-radius: 10px;
}

.payingSummary-modal {
    position: absolute;
    top: 50%;
    left: 50%;
    max-width: 40vw;
    transform: translate(-50%, -50%);
    box-shadow: 24px;
    background: linear-gradient(#363636, #292929);
    padding: 30px;
    border-radius: 10px;
}