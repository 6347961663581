.perfil-container {
    justify-content: center;
    align-items: center;
    min-height: 100vh;
}

.perfil-card {
    width: 40vw;
    padding: 20px 30px;
}

.info-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.info-items {
    width: 48%;
}

.password-container {
    border-top: 1px solid #fff;
    margin-top: 15px;
    padding-top: 15px;
    display: flex;
    align-items: flex-end;
}

.password-container button {
    margin-bottom: 10px;
    margin-left: 4%;
}